



























































import { Component, Mixins, Watch } from 'vue-property-decorator'
import SelectBase from '@/components/atoms/SelectBase.vue'
import ButtonBase from '@/components/atoms/ButtonBase.vue'
import CheckboxSquare from '@/components/atoms/CheckboxSquare.vue'
import EntranceRangeSelectionApi from '@/mixins/student/EntranceRangeSelectionApi'
import _ from 'lodash'

export type SelectedLProblemOptions = {
  prefectureId?: number
  years?: number[]
  curriculumSUnitId?: number
  pageNums?: number[]
}

@Component({
  components: {
    SelectBase,
    ButtonBase,
    CheckboxSquare,
  },
})
export default class EntranceSelectionLProblem extends Mixins(EntranceRangeSelectionApi) {
  private prefectureOptions: { text: string; value: number }[] = []

  private curriculumSUnitOptions: { text: string; value: number }[] = []

  private yearOptions: { label: string; value: number; checked: boolean }[] = []

  private get checkedYears() {
    return this.yearOptions.filter((obj) => obj.checked).map((obj) => obj.value)
  }

  private lProblemOptions: { label: string; value: number; checked: boolean }[] = []

  private get checkedLProblems() {
    return this.lProblemOptions.filter((obj) => obj.checked).map((obj) => obj.value)
  }

  // 選択状態
  private selectedOptions: SelectedLProblemOptions = {}

  private async mounted() {
    this.$emit('loading', true)

    this.prefectureOptions = (await this.loadPrefectures()).map((obj) => {
      return { text: obj.name, value: obj.id }
    })

    this.yearOptions = (await this.loadTargetYears()).map((val) => {
      return { label: `${val}年`, value: val, checked: false }
    })

    // 登録都道府県をデフォルトにセット
    this.$set(this.selectedOptions, 'prefectureId', (await this.loadStudentPrefecture()) || undefined)

    this.$emit('loading', false)
  }

  // 都道府県 or 年度が選択済み
  private get isEnableLoadSUnits() {
    return this.selectedOptions.years?.length == 1 && this.selectedOptions.prefectureId
  }

  private get isEnableLoadProblems() {
    return this.selectedOptions.years?.length && this.selectedOptions.prefectureId
  }

  private inProcess = false

  @Watch('selectedOptions', { deep: true })
  private async onChangeOption(newVal: SelectedLProblemOptions) {
    this.$emit('change-options', newVal)
    if (this.inProcess) return

    this.inProcess = true
    this.$emit('loading', true)

    await this.setCurriculmSUnitsOptions(newVal)
    await this.setLProblemPageNumsOptions(newVal)

    this.inProcess = false
    this.$emit('loading', false)
  }

  // 入試情報取得時のパラメータ
  private prevLoadSUnitsParams = {}

  /**
   * 選択された年度/都道府県の情報に応じて、入試項目選択肢を取得
   */
  private async setCurriculmSUnitsOptions(newVal: SelectedLProblemOptions) {
    const params = {
      year: newVal.years?.[0] as number,
      prefectureId: newVal.prefectureId as number,
    }
    if (_.isEqual(params, this.prevLoadSUnitsParams)) return

    if (this.isEnableLoadSUnits) {
      // テスト名一覧取得
      this.curriculumSUnitOptions = (await this.loadCurriculumSUnits(params)).map((obj) => {
        return { text: obj.name, value: obj.id }
      })
      // 小項目検索時のパラメータ保持
      this.prevLoadSUnitsParams = params
    } else {
      this.curriculumSUnitOptions = []
      this.$set(this.selectedOptions, 'curriculumSUnitId', undefined)

      this.prevLoadSUnitsParams = {}
    }
  }

  // 入試情報取得時のパラメータ
  private prevLoadLProblemPageParams = {}

  /**
   * 選択された年度/都道府県 or 入試名の情報に応じて、大問選択肢を取得
   */
  private async setLProblemPageNumsOptions(newVal: SelectedLProblemOptions) {
    const params = {
      years: newVal.years,
      prefectureId: newVal.prefectureId,
      curriculumSUnitId: newVal.curriculumSUnitId,
    }
    if (_.isEqual(params, this.prevLoadLProblemPageParams)) return

    if (this.isEnableLoadProblems) {
      // 大問番号一覧取得
      this.lProblemOptions = (await this.loadLProblemPageNums(params)).map((num) => {
        return { label: `大問${num}`, value: num, checked: false }
      })
      // 大問取得時のパラメータ保持
      this.prevLoadLProblemPageParams = params
    } else {
      // 初期化 (選択状態はwatcher側で初期化する為、ここでは不要)
      this.lProblemOptions = []
      this.prevLoadLProblemPageParams = {}
    }
  }

  @Watch('checkedYears', { deep: true })
  private onCheckYear(newVal: number[]) {
    this.$set(this.selectedOptions, 'years', newVal)
  }

  @Watch('checkedLProblems', { deep: true })
  private onCheckLProblems(newVal: number[]) {
    this.$set(this.selectedOptions, 'pageNums', newVal)
  }

  public clearOptions() {
    this.selectedOptions = {}
    this.yearOptions.forEach((obj) => (obj.checked = false))
    this.lProblemOptions.forEach((obj) => (obj.checked = false))
  }
}
