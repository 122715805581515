













































import { Component, Mixins, Vue, Ref } from 'vue-property-decorator'
import SidebarSwitcher from '@/components/organisms/v2/SidebarSwitcher.vue'
import ColoredBox from '@/components/atoms/ColoredBox.vue'
import ButtonBase from '@/components/atoms/ButtonBase.vue'
import RadioGroup from '@/components/molecules/RadioGroup.vue'
import StudentBottomPanel from '@/components/organisms/StudentBottomPanel.vue'
import ClassModeChangeAble from '@/mixins/student/ClassModeChangeAble'
import EntranceSelectionPreTest, { SelectedPreTestOptions } from '@/components/organisms/EntranceSelectionPreTest.vue'
import EntranceSelectionLProblem, {
  SelectedLProblemOptions,
} from '@/components/organisms/EntranceSelectionLProblem.vue'
import EntranceSelectionTheme, { SelectedThemeOptions } from '@/components/organisms/EntranceSelectionTheme.vue'
import DrillWebApi from '@/mixins/drills/WebApi'

@Component({
  components: {
    SidebarSwitcher,
    ColoredBox,
    ButtonBase,
    RadioGroup,
    StudentBottomPanel,
    EntranceSelectionPreTest,
    EntranceSelectionLProblem,
    EntranceSelectionTheme,
  },
})
export default class EntranceRangeSelection extends Mixins(ClassModeChangeAble, DrillWebApi) {
  private readonly RADIO_NAME = 'search-type'

  private subjectCode = Vue.prototype.$cookies.get('dataGdls').subjectCode

  // 検索タイプ: 1=>全国高校入試問題, 2=>大問, 3=>テーマ別
  private selectedSearchType = 1

  private get searchRadioItems() {
    const arr = []
    arr.push({ label: '全国高校入試問題', value: 1 })
    arr.push({ label: '大問選択', value: 2 })
    arr.push({ label: 'テーマ別', value: 3 })

    return arr
  }

  // 検索タイプ変更時
  private onSelectSeachType(newVal: number) {
    this.selectedSearchType = newVal
  }

  // 検索タイプ: 全国高校入試問題
  private get isSearchTypePreTest(): boolean {
    return this.selectedSearchType == 1
  }

  // 検索タイプ: 大問選択
  private get isSearchTypeLProblem(): boolean {
    return this.selectedSearchType == 2
  }

  // 検索タイプ: テーマ別
  private get isSearchTypeTheme(): boolean {
    return this.selectedSearchType == 3
  }

  // 全国高校入試問題検索時の選択状態
  private selectedOptionsPreTest: SelectedPreTestOptions = {}

  // 大問検索時の選択状態
  private selectedOptionsLProblem: SelectedLProblemOptions = {}

  // テーマ別検索時の選択状態
  private selectedOptionsTheme: SelectedThemeOptions = {}

  private onChangePreTestOptions(newVal: SelectedPreTestOptions) {
    this.selectedOptionsPreTest = newVal
  }

  private onChangeLProblemOptions(newVal: SelectedLProblemOptions) {
    this.selectedOptionsLProblem = newVal
  }

  private onChangeThemeOptions(newVal: SelectedThemeOptions) {
    this.selectedOptionsTheme = newVal
  }

  public async mounted() {
    this.noticeGroup()
  }

  private noticeCurrentPageText = '条件選択中'

  private async noticeGroup() {
    // このページでは固定文言のためページ遷移時またはbeforeRouteUpdate時に現在ページを伝える
    this.noticeOrStackCurrentPage({ currentPage: this.noticeCurrentPageText })

    // router.pushではsetTimeoutがクリアされないためセットする前にクリアして初期化しておく
    const setTimeoutIdQueueSize = await this.$store.dispatch('queue/size', 'setTimeoutIdQueue')
    for (let i = 0; i < setTimeoutIdQueueSize; i++) {
      const setTimeoutId = await this.$store.dispatch('queue/dequeue', 'setTimeoutIdQueue')
      clearTimeout(setTimeoutId)
    }

    // 5分、10分経過後にポイントを付与する
    this.noticeOrStackCurrentPageDelayed(10, { point: 5 })
    this.noticeOrStackCurrentPageDelayed(20, { point: 10 })
  }

  private showLoading(isShow: boolean) {
    if (isShow) {
      Vue.prototype.$loading.start()
    } else {
      Vue.prototype.$loading.complete()
    }
  }

  /**
   * ドリル開始可能フラグ
   */
  private get isStartAble() {
    let enable = false

    // 全国高校入試問題選択バリデーション
    if (this.isSearchTypePreTest) {
      const params = this.selectedOptionsPreTest
      if (params.prefectureId && params.year && params.curriculumSUnitId) enable = true
    }

    // 大問選択バリデーション
    if (this.isSearchTypeLProblem) {
      const params = this.selectedOptionsLProblem
      if (params.prefectureId && params.years?.length && params.pageNums?.length) enable = true
    }

    // テーマ別選択バリデーション
    if (this.isSearchTypeTheme) {
      const params = this.selectedOptionsTheme
      if (typeof params.includeLivingPrefecture !== 'undefined' && params.curriculumSUnitIds?.length) enable = true
    }

    return enable
  }

  @Ref() entranceSelectionPreTest!: EntranceSelectionPreTest
  @Ref() entranceSelectionLProblem!: EntranceSelectionLProblem
  @Ref() entranceSelectionTheme!: EntranceSelectionTheme

  private onClickClear() {
    if (this.isSearchTypePreTest) this.entranceSelectionPreTest.clearOptions()
    if (this.isSearchTypeLProblem) this.entranceSelectionLProblem.clearOptions()
    if (this.isSearchTypeTheme) this.entranceSelectionTheme.clearOptions()
  }

  private async onClickStart() {
    // 開始条件を満たしていない場合は押下不可
    if (!this.isStartAble) return

    // TODO: 抽出条件
    let params: any = {}
    if (this.isSearchTypePreTest) {
      // プレテスト用パラメータ
      params = {
        lessonId: this.lessonId,
        classModeCode: this.currentClassMode,
        classCategoryCode: 'EN',
        searchType: 'NP',
        curriculumSUnitId: this.selectedOptionsPreTest.curriculumSUnitId,
        subjectCode: this.subjectCode,
      }
    } else if (this.isSearchTypeLProblem) {
      // 大問選択用パラメータ
      params = {
        lessonId: this.lessonId,
        classModeCode: this.currentClassMode,
        classCategoryCode: 'EN',
        searchType: 'NL',
        curriculumSUnitId: this.selectedOptionsLProblem.curriculumSUnitId,
        years: this.selectedOptionsLProblem.years,
        prefectureId: this.selectedOptionsLProblem.prefectureId,
        pageNums: this.selectedOptionsLProblem.pageNums,
        subjectCode: this.subjectCode,
      }
    } else if (this.isSearchTypeTheme) {
      // テーマ指定用パラメータ
      params = {
        lessonId: this.lessonId,
        classModeCode: this.currentClassMode,
        classCategoryCode: 'EN',
        searchType: 'NT',
        includeLivingPrefecture: this.selectedOptionsTheme.includeLivingPrefecture,
        curriculumSUnitIds: this.selectedOptionsTheme.curriculumSUnitIds,
        subjectCode: this.subjectCode,
      }
    }

    // 以下は一旦他で行われている処理をコピペしてコメントアウトしてます。

    Vue.prototype.$loading.start()
    const startDrill = await this.startDrillApi(params)

    // ドリル開始APIの結果を受けて問題ページへ遷移する
    this.$router.push({
      name: 'drill',
      query: {
        resultDrillId: String(startDrill.resultDrillId),
        pageType: startDrill.pageType,
        page: String(startDrill.page || ''),
        classCategoryCode: 'EN', // 授業種別
      },
    })
    Vue.prototype.$loading.complete()
  }
}
