
















































import { Component, Mixins, Watch } from 'vue-property-decorator'
import SelectBase from '@/components/atoms/SelectBase.vue'
import ButtonBase from '@/components/atoms/ButtonBase.vue'
import EntranceRangeSelectionApi from '@/mixins/student/EntranceRangeSelectionApi'

export type SelectedPreTestOptions = {
  prefectureId?: number
  year?: number
  curriculumSUnitId?: number
}

@Component({
  components: {
    SelectBase,
    ButtonBase,
  },
})
export default class EntranceSelectionPreTest extends Mixins(EntranceRangeSelectionApi) {
  private prefectureOptions: { text: string; value: number }[] = []

  private yearOptions: { text: string; value: number }[] = []

  private curriculumSUnitOptions: { text: string; value: number }[] = []

  private selectedOptions: SelectedPreTestOptions = {}

  private async mounted() {
    this.$emit('loading', true)

    this.prefectureOptions = (await this.loadPrefectures()).map((obj) => {
      return { text: obj.name, value: obj.id }
    })

    this.yearOptions = (await this.loadTargetYears()).map((val) => {
      return { text: `${val}年`, value: val }
    })

    // 登録都道府県をデフォルトにセット
    this.$set(this.selectedOptions, 'prefectureId', (await this.loadStudentPrefecture()) || undefined)

    this.$emit('loading', false)
  }

  // 都道府県 or 年度が選択済み
  private get isEnableLoadSUnits() {
    return this.selectedOptions.year && this.selectedOptions.prefectureId
  }

  // 入試情報取得時の年度-都道府県ペア
  private prevPairYearPrefecture = ''

  @Watch('selectedOptions', { deep: true })
  private onChangeOption(newVal: SelectedPreTestOptions) {
    this.$emit('change-options', newVal)

    if (this.isEnableLoadSUnits) {
      const pair = `${newVal.year}-${newVal.prefectureId}`
      if (pair == this.prevPairYearPrefecture) return

      const params = {
        year: newVal.year as number,
        prefectureId: newVal.prefectureId as number,
      }

      this.$emit('loading', true)
      // テスト名一覧取得
      this.loadCurriculumSUnits(params).then((data) => {
        this.curriculumSUnitOptions = data.map((obj) => {
          return { text: obj.name, value: obj.id }
        })

        this.$set(this.selectedOptions, 'curriculumSUnitId', this.curriculumSUnitOptions[0]?.value || undefined)
        this.$emit('loading', false)
      })

      this.prevPairYearPrefecture = pair
    } else {
      this.curriculumSUnitOptions = []
      this.$set(this.selectedOptions, 'curriculumSUnitId', undefined)

      this.prevPairYearPrefecture = ''
    }
  }

  public clearOptions() {
    this.selectedOptions = {}
  }
}
