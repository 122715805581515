import { Component, Vue } from 'vue-property-decorator'

export type ThemesRes = {
  id: number
  name: string
  sUnits: { id: number; name: string; prefectureTrends: { year: number; score: number }[] }[]
}[]

@Component
export default class EntranceRangeSelectionApi extends Vue {
  private get subjectCode() {
    return Vue.prototype.$cookies.get('dataGdls').subjectCode
  }

  /**
   * 登録済みの都道府県取得
   */
  protected async loadStudentPrefecture(): Promise<number | null> {
    const result = await Vue.prototype.$http.httpWithToken.get('/entrance/student_prefectures')

    return result.data?.prefectureId || null
  }

  /**
   * 実施年一覧取得
   */
  protected async loadTargetYears(): Promise<number[]> {
    const params = { subjectCode: this.subjectCode }
    const result = await Vue.prototype.$http.httpWithToken.get('/entrance/curriculum_s_units/target_years', {
      params: params,
    })

    return result.data.years || []
  }

  /**
   * 都道府県一覧取得
   */
  protected async loadPrefectures(): Promise<{ id: number; name: string }[]> {
    const result = await Vue.prototype.$http.httpWithToken.get('/prefectures')

    return result.data || []
  }

  /**
   * 入試項目名取得
   */
  protected async loadCurriculumSUnits(params: {
    year: number
    prefectureId: number
  }): Promise<{ id: number; name: string }[]> {
    const result = await Vue.prototype.$http.httpWithToken.get('/entrance/curriculum_s_units', {
      params: Object.assign({ subjectCode: this.subjectCode }, params),
    })

    return result.data || []
  }

  /**
   * 大問番号リスト取得
   */
  protected async loadLProblemPageNums(params: {
    years?: number[]
    prefectureId?: number
    curriculumSUnitId?: number
  }): Promise<number[]> {
    const result = await Vue.prototype.$http.httpWithToken.get('/entrance/l_problems/page_nums', {
      params: Object.assign({ subjectCode: this.subjectCode }, params),
    })

    return result.data.pageNums || []
  }

  protected async loadThemes(): Promise<ThemesRes> {
    const params = { subjectCode: this.subjectCode }
    const result = await Vue.prototype.$http.httpWithToken.get('/entrance/curriculum_s_units/themes', {
      params: params,
    })

    return result.data || []
  }
}
