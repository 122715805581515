














































import { Component, Mixins, Watch } from 'vue-property-decorator'
import SelectBase from '@/components/atoms/SelectBase.vue'
import ButtonBase from '@/components/atoms/ButtonBase.vue'
import RadioGroup from '@/components/molecules/RadioGroup.vue'
import CheckboxSquare from '@/components/atoms/CheckboxSquare.vue'
import EntranceRangeSelectionApi from '@/mixins/student/EntranceRangeSelectionApi'

export type SelectedThemeOptions = {
  includeLivingPrefecture?: boolean
  curriculumSUnitIds?: number[]
}

type ThemaOptions = {
  id: number
  name: string
  checked: boolean
  sUnits: { id: number; name: string; checked: boolean; prefectureTrends: { year: number; score: number }[] }[]
}[]

@Component({
  components: {
    SelectBase,
    ButtonBase,
    RadioGroup,
    CheckboxSquare,
  },
})
export default class EntranceSelectionTheme extends Mixins(EntranceRangeSelectionApi) {
  private readonly RADIO_NAME = 'living-prefecture'

  private livingPrefectureRadioItems = [
    { label: '自分の県の問題も出す', value: 1 },
    { label: '自分の県の問題は出さない', value: 0 },
  ]

  private targetYears: number[] = []

  private selectedOptions: SelectedThemeOptions = {}

  private themaOptions: ThemaOptions = []

  private get checkedSUnits(): number[] {
    const arr: number[] = []
    this.themaOptions.forEach((mUnit) => {
      mUnit.sUnits.forEach((sUnit) => {
        if (sUnit.checked) arr.push(sUnit.id)
      })
    })
    return arr
  }

  @Watch('checkedSUnits', { deep: true })
  private onCheckSUnit(newVal: number[]) {
    this.$set(this.selectedOptions, 'curriculumSUnitIds', newVal)
  }

  @Watch('selectedOptions', { deep: true })
  private async onChangeOption(newVal: SelectedThemeOptions) {
    this.$emit('change-options', newVal)
  }

  private onSelectLivingPrefectureOption(newVal: number) {
    this.$set(this.selectedOptions, 'includeLivingPrefecture', newVal == 1)
  }

  private async mounted() {
    this.$emit('loading', true)
    this.$set(this.selectedOptions, 'includeLivingPrefecture', true)
    this.targetYears = await this.loadTargetYears()
    this.themaOptions = (await this.loadThemes()).map((mUnit) => {
      return {
        id: mUnit.id,
        name: mUnit.name,
        checked: false,
        sUnits: mUnit.sUnits.map((sUnit) => {
          return {
            id: sUnit.id,
            name: sUnit.name,
            checked: false,
            prefectureTrends: sUnit.prefectureTrends,
          }
        }),
      }
    })
    this.$emit('loading', false)
  }

  private getYearStatus(
    year: number,
    sUnit: { name: string; checked: boolean; prefectureTrends: { year: number; score: number }[] }
  ) {
    const score = sUnit.prefectureTrends?.find((obj) => obj.year == year)?.score
    if (score == 1) return '△'
    if (score == 2) return '◯'
    if (score && score >= 3) return '◎'

    return '-'
  }

  private onCheckCategory(category_id: number, checked: boolean) {
    const category = this.themaOptions.find((obj) => obj.id == category_id)
    if (checked) {
      category?.sUnits.forEach((obj) => (obj.checked = true))
    } else {
      category?.sUnits.forEach((obj) => (obj.checked = false))
    }
  }

  private onCheckTheme(category_id: number, checked: boolean) {
    if (!checked) {
      const category = this.themaOptions.find((obj) => obj.id == category_id)
      if (category) category.checked = false
    }
  }

  private getCategoryColumnStyle(index: number, rowCount: number) {
    // 分野毎の最初の行は上部、最終行は下部に罫線を引く
    const isFirstRow = index == 0
    const isLastRow = index + 1 == rowCount
    return {
      '--border-top': isFirstRow ? 'solid 1px' : 'none',
      '--border-bottom': isLastRow ? 'solid 1px' : 'none',
    }
  }

  public clearOptions() {
    this.$set(this.selectedOptions, 'includeLivingPrefecture', true)
    this.themaOptions.forEach((mUnit) => {
      mUnit.checked = false
      mUnit.sUnits.forEach((sUnit) => (sUnit.checked = false))
    })
  }
}
